/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    em: "em"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "First things first, what is reopening anxiety? The term is used to explain the uncertainty around the lifting of lockdowns and covid restrictions in the UK. As someone who’s grown up with social anxiety, I’ve avoided unnecessary social contact for most of my adult/adolescent life anyway, so there’s certain restrictions I’ve felt quite positive about (eg. strangers having to keep their distance from you). I’ve also had covid and let me tell you, it was not pleasant and I have a vested interest to not catch it again."), "\n", React.createElement(_components.p, null, "Should I still be wearing a mask? That’s the question on everyone’s lips at the minute. Whilst it’ll no longer be mandatory to wear one, I personally will still be wearing one in any busy/crowded indoor space. Mask wearing is something I’d quite like to stay around after the pandemic - if you’ve got a cold or are feeling run down, you could pop a mask on to lower the risk of infecting others. Only time will tell if this habit sticks around or not, but I certainly don’t think we should be shaming anyone who continues to wear them. I also think we should remain mindful of people’s personal space and not be breathing down their necks in queues etc. "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "I don’t know about you but it’s bizarre to think things like nightclubs just haven’t existed in our lives for the past year and a half and now they’re coming back like nothing ever happened. I personally can’t wrap my head around how just this week I was having to book a table to go for cocktails in a socially distanced bar and dancing was prohibited, yet next week I could be in a sweaty, packed nightclub (feeling like a tinned sardine), two stepping the night away. Personally, I’d prefer it if they kept some capacity limits on places like bars and clubs for now - though I don’t think I’ll be returning to a nightclub any time soon. "), "\n", React.createElement(_components.p, null, "Returning to the workplace or continuing to work from home? I personally have always had a blended work schedule where I’m in the office some days and working from home for others - so this will continue for me. Everyone feels different about this topic. Some want to continue working from home as it offers them more flexibility, less time commuting, less money on petrol or parking and they don’t feel safe returning to a workplace. Others can’t wait to separate their home/work life into separate locations, socialise with their colleagues in person and get out of the four walls they’ve spent the past year and a half stuck in. I commend the companies offering a more blended approach to work and personally think this is a healthy and fair way forward for those who can work from home in their jobs. It’s completely understandable that people will have various anxieties around returning to their place of work, or returning to work full stop if they’ve been furloughed, so I think it’s more important than ever to be kind to people. If this is you, speak to your HR team or your manager to see what support can be offered. "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n", React.createElement(_components.p, null, "I’m not going to go through every restriction change and how I feel about it, but I think the overwhelming message is that it’s completely normal to be nervous about all the changes. Continue to do what makes you feel comfortable, be understanding of and patient with those who feel different to you and continue to be sensible. There are people you can speak to if you’re struggling with the change, be it friends and family, the HR team at work, a therapist or a mental health organisation (you don’t need to be diagnosed with a mental health problem to access these services)."), "\n", React.createElement(_components.p, null, "For text support, text ", React.createElement(_components.a, {
    href: "https://giveusashout.org/"
  }, "SHOUT"), " to 85258."), "\n", React.createElement(_components.p, null, "Call ", React.createElement(_components.a, {
    href: "https://www.mind.org.uk/information-support/helplines/"
  }, "MINDs"), " advice line to find local support 0300 123 3393 "), "\n", React.createElement(_components.p, null, "Call ", React.createElement(_components.a, {
    href: "https://www.samaritans.org/"
  }, "Samaritans"), " for free, any time on 116 123 or email them ", React.createElement(_components.a, {
    href: "mailto:jo@samaritans.org"
  }, "jo@samaritans.org"), " "), "\n", React.createElement(_components.p, null, "Read about support and advice online at ", React.createElement(_components.a, {
    href: "https://www.nhs.uk/every-mind-matters/"
  }, "NHS Every Mind Matters"), ". "), "\n", React.createElement(_components.p, null, "Call 999 if you are in immediate danger (UK)."), "\n", React.createElement(_components.p, null, React.createElement(_components.em, null, "“Have patience with all things, but first of all, with yourself.”")), "\n", React.createElement(_components.p, null, "Sarah x"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
